var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "ui-wrapper" }, [
      _c("div", { staticClass: "left-panel" }, [
        _c(
          "div",
          { staticClass: "components-list" },
          _vm._l(_vm.LeftComponents, function (item, listIndex) {
            return _c(
              "div",
              { key: listIndex, staticClass: "component-list-item" },
              [
                _c(
                  "div",
                  { staticClass: "components-title" },
                  [
                    _c("a-icon", {
                      staticClass: "title-icon",
                      attrs: { type: item.icon },
                    }),
                    _vm._v(" " + _vm._s(item.title) + " "),
                  ],
                  1
                ),
                _c(
                  "draggable",
                  {
                    staticClass: "components-draggable",
                    attrs: {
                      list: item.list,
                      group: {
                        name: "componentsGroup",
                        pull: "clone",
                        put: false,
                      },
                      draggable: ".components-item",
                    },
                    on: { end: _vm.onEnd },
                  },
                  _vm._l(item.list, function (element, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "components-item",
                        on: {
                          click: function ($event) {
                            return _vm.addComponent($event, element)
                          },
                          mousedown: function ($event) {
                            return _vm.check($event, element)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              "components-body",
                              { disabled: element.disabled },
                            ],
                          },
                          [_vm._v(" " + _vm._s(element.displayName) + " ")]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "center-panel" },
        [
          _c(
            "jtl-button",
            {
              staticClass: "middle-button",
              attrs: { type: "primary" },
              on: { click: _vm.saveDesign },
            },
            [_vm._v("保存排版")]
          ),
          _c(
            "draggable",
            {
              staticClass: "drawing-board",
              attrs: {
                list: _vm.centerComponents,
                animation: 340,
                group: "componentsGroup",
              },
              on: { end: _vm.onCenterEnd },
            },
            _vm._l(_vm.centerComponents, function (item, itemIndex) {
              return _c(
                "div",
                {
                  key: itemIndex,
                  staticClass: "drawing-item-wrapper",
                  on: {
                    mousedown: function ($event) {
                      return _vm.setActiveComponent(itemIndex)
                    },
                  },
                },
                [
                  !item.name
                    ? _c("a-icon", {
                        staticClass: "warning-icon",
                        attrs: { type: "warning", color: "red" },
                      })
                    : _vm._e(),
                  item.dataType === "SEPERATOR"
                    ? _c("div", { staticClass: "seperator" })
                    : item.dataType === "RICHTEXT"
                    ? _c(
                        "div",
                        {
                          staticClass: "drawing-item",
                          class: { selected: item.selected },
                        },
                        [
                          _c("div", { staticClass: "required-icon-textarea" }, [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.required,
                                    expression: "item.required",
                                  },
                                ],
                              },
                              [_vm._v("*")]
                            ),
                          ]),
                          _c("div", { staticClass: "form-flex-column-item" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(" " + _vm._s(item.displayName) + " "),
                            ]),
                            _c("div", { staticClass: "form-input-textarea" }, [
                              _vm._v(" 请描述一下吧... "),
                            ]),
                          ]),
                        ]
                      )
                    : item.dataType === "IMAGE" || item.dataType === "IMAGES"
                    ? _c(
                        "div",
                        {
                          staticClass: "drawing-item",
                          class: { selected: item.selected },
                        },
                        [
                          _c("div", { staticClass: "required-icon-textarea" }, [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.required,
                                    expression: "item.required",
                                  },
                                ],
                              },
                              [_vm._v("*")]
                            ),
                          ]),
                          _c("div", { staticClass: "form-flex-column-item" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(" " + _vm._s(item.displayName) + " "),
                            ]),
                            _c("div", { staticClass: "form-input-picture" }, [
                              _c(
                                "div",
                                { staticClass: "form-upload-img-selector" },
                                [
                                  _c("a-icon", {
                                    staticClass: "upload-icon",
                                    attrs: { type: "upload", color: "#ABAFB3" },
                                  }),
                                  _c("span", { staticClass: "upload-text" }, [
                                    _vm._v(" 上传图片 "),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "drawing-item",
                          class: { selected: item.selected },
                        },
                        [
                          _c("div", { staticClass: "required-icon" }, [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.required,
                                    expression: "item.required",
                                  },
                                ],
                              },
                              [_vm._v("*")]
                            ),
                          ]),
                          _c("div", { staticClass: "title" }, [
                            _vm._v(" " + _vm._s(item.displayName) + " "),
                          ]),
                          _c("div", { staticClass: "place-holder" }, [
                            _vm._v(
                              _vm._s(
                                item.dataType === "TEXT" ? "请输入" : "请选择"
                              )
                            ),
                          ]),
                          item.dataType !== "TEXT"
                            ? _c("a-icon", {
                                staticClass: "right-icon",
                                attrs: { type: "right" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.centerComponents.length,
                  expression: "!centerComponents.length",
                },
              ],
              staticClass: "empty-info",
            },
            [_vm._v(" 从左侧拖入或点选组件进行表单设计 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-panel" },
        [
          _vm.selectedComponent
            ? _c(
                "a-card",
                { attrs: { title: "组件属性" } },
                [
                  _c("jtl-form", {
                    ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME,
                    on: { change: _vm.formChanged },
                  }),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "确认删除?",
                        "ok-text": "确认",
                        "cancel-text": "取消",
                      },
                      on: {
                        confirm: function ($event) {
                          return _vm.deleteClick()
                        },
                      },
                    },
                    [
                      _c(
                        "jtl-button",
                        { staticClass: "del-btn", attrs: { type: "danger" } },
                        [_vm._v(" 删除组件 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }