













































































































import draggable from 'vuedraggable';
import { Component, Prop } from 'vue-property-decorator';
import { CenterComponent } from '@common-src/model/work-order-form-ui';
import FormComponent from '@common-src/mixins/form-component';
import { ViewModeType } from '@common-src/model/enum';
import { FormControlType } from '@common-src/model/form-control';
import WorkOrderFormDesignService from '@common-src/service/work-order-form-design';

@Component({
    components: {
        'draggable': draggable
    }
})
export default class WorkOrderFormDesignUIComponent extends FormComponent<CenterComponent> {
    @Prop()
    formId: string;

    LeftComponents = [{
        title: '内置组件',
        id: 'system',
        icon: 'setting',
        list: [
            {
                // 分隔符
                displayName: '分隔符',
                name: 'seperator',
                dataType: 'SEPERATOR',
                index: -1,
                system: false,
                required: false,
                visible: true,
                readonly: true,
                disabled: false,
                groupId: 0
            }
        ]
    },
    {
        title: '自定义组件',
        id: 'customize',
        icon: 'form',
        list: []
    }];

    centerComponents: Array<CenterComponent> = [];

    selectedComponent: CenterComponent = null;

    selectedComponentIndex: number = 0;

    created() {
        new WorkOrderFormDesignService(this.formId).query().then(res => {
            // 初始化排版
            let previousGroupId = 0;

            _.map(res, item => {
                if (item.groupId > 0) {
                    item.disabled = true;

                    if (previousGroupId === 0) {
                        previousGroupId = item.groupId;
                    }

                    if (item.groupId !== previousGroupId) {
                        this.centerComponents.push(new CenterComponent().toModel(this.LeftComponents[0].list[0]));

                        previousGroupId = item.groupId;
                    }

                    this.centerComponents.push(new CenterComponent().toModel(item));
                }
            });

            const cLength = this.centerComponents.length;

            if (cLength > 0 && this.centerComponents[cLength - 1].name === 'seperator') {
                this.centerComponents.splice(cLength - 1, 1);
            }

            // 初始化左侧控件
            let systems = _.filter(res, item => { return item.system; });

            this.LeftComponents[0].list = [...this.LeftComponents[0].list, ...systems];

            let matches = _.filter(res, item => { return !item.system; });

            this.LeftComponents[1].list = matches;
        });
    }

    // 点击左侧组件添加组件
    addComponent(e, element) {
        if (element.disabled) {
            this.showMessageWarning('组件已使用或暂时不支持使用！');

            e.stopPropagation();

            e.preventDefault();

            return;
        }

        const cloneElement = JSON.parse(JSON.stringify(element));

        this.centerComponents.push(new CenterComponent().toModel(cloneElement));

        // 选中新拖入的组件
        this.setActiveComponent(this.centerComponents.length - 1);

        // 左侧菜单禁用组件（内置组件）
        if (element.dataType !== 'SEPERATOR') {
            element.disabled = true;
        }
    }

    // 拖拽左侧组件添加组件
    onEnd(e) {
        const index = e.newIndex;
        if (index > this.centerComponents.length - 1) {
            return;
        }

        const cloneItem = JSON.parse(JSON.stringify(this.centerComponents[index]));

        // 选中新拖入的组件
        this.centerComponents.splice(index, 1, new CenterComponent().toModel(cloneItem));

        this.setActiveComponent(index);

        // 左侧菜单禁用组件（内置组件）
        _.map(this.LeftComponents, left => {
            _.map(left.list, item => {
                if (item.name === cloneItem.name && cloneItem.dataType !== 'SEPERATOR') {
                    item.disabled = true;
                }
            });
        });
    }

    // 中间组件位置变化
    onCenterEnd(e) {
        this.selectedComponentIndex = e.newIndex;
    }

    // 选中组件
    setActiveComponent(index) {
        let component = this.centerComponents[index];

        component.selected = true;

        this.selectedComponentIndex = index;

        this.selectedComponent = component;

        _.map(this.centerComponents, (item, itemIndex) => {
            if (itemIndex !== index) {
                item.selected = false;
            }
        });

        this.$nextTick(() => {
            (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME] as any).initForm(component, ViewModeType.UPDATE);
        });
    }

    // 检查左侧组件是否可添加
    check(e, element) {
        // 不允许拖拽
        if (element.disabled) {
            this.showMessageWarning('组件已使用或暂时不支持使用！');

            e.stopPropagation();

            e.preventDefault();
        }
    }

    formChanged(formControl) {
        if (formControl.type === FormControlType.CHECKBOX) {
            this.selectedComponent[formControl.key] = formControl.value;
        }
    }

    deleteClick() {
        const component = this.centerComponents[this.selectedComponentIndex];

        this.centerComponents.splice(this.selectedComponentIndex, 1);

        // 左侧组件库取消禁用
        _.map(this.LeftComponents, left => {
            _.map(left.list, item => {
                if (item.name === component.name) {
                    item.disabled = false;
                }
            });
        });

        // 默认选中下一个
        if (this.centerComponents.length > 0) {
            if (this.selectedComponentIndex === this.centerComponents.length) {
                // 若删除最后一个，默认选中删除后最后一个组件
                this.selectedComponentIndex = this.centerComponents.length - 1;
            }

            this.setActiveComponent(this.selectedComponentIndex);
        } else {
            this.selectedComponentIndex = 0;

            this.selectedComponent = null;
        }
    }
    saveDesign() {
        const cloneComponents = JSON.parse(JSON.stringify(this.centerComponents));

        // 校验
        const verify = _.findIndex(this.centerComponents, item => { return !item.name; }) === -1;

        if (!verify) {
            this.showMessageError('校验失败');

            return;
        }

        // 添加groupId参数
        let groupComponents = [];

        let newIndex = 0;

        _.map(cloneComponents, (item, index) => {
            delete item.parent;

            if (item.dataType === 'SEPERATOR') {
                _.map(groupComponents, item => {
                    item.groupId = index;
                });

                groupComponents = [];
            } else {
                item.index = newIndex;

                newIndex++;

                groupComponents.push(item);
            }
        });

        _.map(groupComponents, item => {
            item.groupId = cloneComponents.length;
        });

        // 表单排版字段
        const result = _.map(_.filter(cloneComponents, item => {
            return item.dataType !== 'SEPERATOR';
        }), item => {
            return new CenterComponent().toModel(item).toService();
        });

        // 其余字段
        const otherFields = [];

        _.map(this.LeftComponents, left => {
            _.map(left.list, item => {
                // 字段未使用
                if (!item.disabled && item.name !== 'seperator') {
                    item.visible = false;

                    item.readonly = true;

                    item.required = false;

                    item.index = newIndex;

                    item.groupId = 0;

                    newIndex++;

                    otherFields.push(new CenterComponent().toModel(item).toService());
                }
            });
        });

        const fields = [...result, ...otherFields];

        new WorkOrderFormDesignService(this.formId).saveUI(this.formId, fields).then(res => {
            this.showMessageSuccess('保存成功！');
        });
    }
}

