var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-order-form-design-fields-component table-component" },
    [
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-card",
            { attrs: { title: "表单字段列表" } },
            [
              _c(
                "jtl-button",
                {
                  staticClass: "table-header-button",
                  attrs: { slot: "extra", type: "primary" },
                  on: { click: _vm.add },
                  slot: "extra",
                },
                [_vm._v("+ 新增字段")]
              ),
              _c("a-table", {
                attrs: {
                  loading: _vm.listLoading,
                  columns: _vm.tableColumns,
                  "data-source": _vm.listData,
                  pagination: false,
                  scroll: _vm.tableScroll,
                  "row-key": _vm.TableRowKey,
                  size: _vm.TableSize,
                },
                on: { change: _vm.tableChange },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function (text, record, index) {
                      return [_vm._v(_vm._s(index + 1))]
                    },
                  },
                  {
                    key: "action",
                    fn: function (text, record) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "jtl-edit-link",
                            on: {
                              click: function ($event) {
                                return _vm.editClick(record, true)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: "确认删除?",
                              "ok-text": "确认",
                              "cancel-text": "取消",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.deleteClick(record)
                              },
                            },
                          },
                          [
                            !record.system
                              ? _c("a", { staticClass: "jtl-del-link" }, [
                                  _vm._v("删除"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.formDialogOK, change: _vm.formChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }