import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import { BaseModel } from '@common-src/model/base-model';

export class CenterComponent extends BaseModel {
    id: string = '';
    index: number = 0;
    selected: boolean = false;
    groupId: number = 0;
    parent: string = '';

    @FormControl({
        label: '字段名称',
        type: FormControlType.TEXT,
        layout: { labelCol: 8, wrapperCol: 15, width: '100%' },
        required: true,
        readonly: true,
        max: 50
    } as FormControlTextModel)
    displayName: string = undefined;

    @FormControl({
        label: '标识符',
        type: FormControlType.TEXT,
        layout: { labelCol: 8, wrapperCol: 15, width: '100%' },
        required: true,
        readonly: true,
        max: 50
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '字段类型',
        type: FormControlType.TEXT,
        layout: { labelCol: 8, wrapperCol: 15, width: '100%' },
        required: true,
        readonly: true,
        max: 50
    } as FormControlTextModel)
    dataType: string = undefined;

    @FormControl({
        label: '系统字段',
        type: FormControlType.CHECKBOX,
        layout: { labelCol: 8, wrapperCol: 3, width: '100%' },
        readonly: true,
        required: true,
        invisibleFunction: model => model.dataType === 'SEPERATOR'
    } as FormControlOptionModel)
    system: boolean = true;

    @FormControl({
        label: '默认必填',
        type: FormControlType.CHECKBOX,
        layout: { labelCol: 8, wrapperCol: 3, width: '100%' },
        required: true,
        invisibleFunction: model => model.dataType === 'SEPERATOR'
    } as FormControlOptionModel)
    required: boolean = false;

    @FormControl({
        label: '默认只读',
        type: FormControlType.CHECKBOX,
        layout: { labelCol: 8, wrapperCol: 3, width: '100%' },
        required: true,
        invisibleFunction: model => model.dataType === 'SEPERATOR'
    } as FormControlOptionModel)
    readonly: boolean = true;

    @FormControl({
        label: '默认可见',
        type: FormControlType.CHECKBOX,
        layout: { labelCol: 8, wrapperCol: 3, width: '100%' },
        required: true,
        invisibleFunction: model => model.dataType === 'SEPERATOR'
    } as FormControlOptionModel)
    visible: boolean = true;

    toService() {
        const res = {
            id: this.id,
            index: this.index,
            groupId: this.groupId,
            visible: this.visible,
            required: this.required,
            readonly: this.readonly
        };
        return res;
    }
}
